
// for local frontend with local backend
const localFrontendWithLocalBackend = {
  baseUrl: 'http://localhost:3000/api/v1/',
  cableUrl: 'ws://localhost:3000/cable',
  siteUrl: 'http://localhost:3000',
  frontendUrl: 'http://localhost:3001',
  clientId: 'L_VA-RfANXvY4eKMXCFXSaty7XkRkhXsidnUTiTMfeo',
  forgotPasswordUrl: 'http://localhost:3000/registrations/password/new'
}

const LocalIP = '192.168.178.33'

const lanFrontendWithLanBackend = {
  baseUrl: `http://${LocalIP}:3000/api/v1/`,
  cableUrl: `ws://${LocalIP}:3000/cable`,
  siteUrl: `http://${LocalIP}:3000`,
  frontendUrl: `http://${LocalIP}:3001`,
  clientId: 'w_L0ptwmuPk80hF4EnG5jq_GYwUWOS9G0RViTS5Makk',
  forgotPasswordUrl: `http://${LocalIP}:3000/registrations/password/new`
}

const lanFrontendWithStagingBackend = {
  baseUrl: 'https://knestel-iot.review-app.de/api/v1/',
  siteUrl: 'https://knestel-iot.review-app.de',
  frontendUrl: `http://${LocalIP}:3001`,
  clientId: 'IR_m0xVR9VowRtxtID8YS1v_l13SrM6nuMnz8voxI-E',
  forgotPasswordUrl: 'https://knestel-iot.review-app.de/registrations/password/new'
}

// for local frontend with staging backend
const localFrontendWithStagingBackend = {
  baseUrl: 'https://knestel-iot.review-app.de/api/v1/',
  siteUrl: 'https://knestel-iot.review-app.de',
  frontendUrl: 'http://localhost:3000',
  clientId: 'IR_m0xVR9VowRtxtID8YS1v_l13SrM6nuMnz8voxI-E',
  forgotPasswordUrl: 'https://knestel-iot.review-app.de/registrations/password/new'
}

const localFrontendWithProductionBackend = {
  baseUrl: 'https://services.starcleancloud.de/api/v1/',
  siteUrl: 'https://services.starcleancloud.de',
  frontendUrl: 'https://localhost:3000',
  clientId: 'L_VA-RfANXvY4eKMXCFXSaty7XkRkhXsidnUTiTMfeo',
  forgotPasswordUrl: 'https://services.starcleancloud.de/registrations/password/new'
}

const env = {
  development: { ...localFrontendWithStagingBackend },
  production: {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    cableUrl: process.env.REACT_APP_CABLE_URL,
    siteUrl: process.env.REACT_APP_IMAGE_BASE_URL,
    frontendUrl: process.env.REACT_APP_FRONTEND_URL,
    clientId: process.env.REACT_APP_CLIENT_ID,
    forgotPasswordUrl: `${process.env.REACT_APP_IMAGE_BASE_URL}/registrations/password/new`
  }
}

const environmentConfig = env[process.env.NODE_ENV]

export default environmentConfig
