import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Loader from '../../../components/Ux/Loader'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Sidebars from '../../../components/layouts/Sidebars'
import { Grid } from '@material-ui/core'
import useSetControlTitle from '../../../components/hooks/useSetControlTitle'
import { useDispatch, useSelector } from 'react-redux'
import { CUSTOMER_CONTROL_GET_VARIABLES_REQUEST, CUSTOMER_CONTROL_GET_VARIABLES_CHART_REQUEST, INFO_SCREEN_SCRAPER_SELECTED, INFO_SCREEN_SCRAPER_TAB_SELECTED } from '../../../store/customerControl/customerControlActions'
import { mapVariables } from '../../../helpers/variableMappers'
import { BELT_CONVEYOR_SCRAPERS_LIST, BELT_CONVEYOR_SCRAPPER_SEGMENT_MAP } from '../../../constants/variables'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import Pagination from '@material-ui/lab/Pagination'
import * as variablesConstants from '../../../constants/variables'
import {
  MESSAGE_LIST_REQUEST
  , MESSAGE_SCRAPER_LIST_REQUEST
} from '../../../store/message/messageAction'
import Moment from 'react-moment'
import moment from 'moment'
import useSetMessageIcon from '../../../components/hooks/useSetMessageIcon'
import NumberFormat from '../../../components/utilities/numberFormat'

import useShowConnectionSymbol from '../../../components/hooks/useShowConnectionSymbol'
import {
  SERVICE_USER,
  SUPER_USER
} from '../../../constants/roles'
import { PollControlData } from '../../../components/polling'
import TabSegment from './ConveyorBeltInfoScraper/TabSegment'

const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }
}))

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div': {
      flex: '0 0 49.5%'
    },
    '& > .fullwidth': {
      flex: '0 0 100%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(odd)': {
      marginRight: '0.5%'
    },
    '& > div:not([class="fullwidth"]):nth-of-type(even)': {
      marginLeft: '0.5%'
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  },
  messageBtn: {
    minWidth: '100%',
    minHeight: '60px',
    border: '1px solid black',
    borderRadius: 'none'
  }
}))

const varNrToScraperNr = {
  601: 1,
  701: 2,
  801: 3,
  901: 4
}

const ConveyorBeltInfoScraper = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const isMount = useRef(false)
  const isErrorMount = useRef(false)
  const isMessagesMount = useRef(false)
  const isScraperMessagesMount = useRef(false)
  const [showLoader, setShowLoader] = useState(false)
  const actionsClasses = useActions()
  const { t } = useTranslation()
  const params = useParams()
  const customerControl = useSelector((state) => state.customerControl) || {}
  const { variables, control } = customerControl
  const { selectedScraper, selectedScraperTab } = control || { selectedScraper: 601, selectedScraperTab: 0 }
  const scraperNumber = varNrToScraperNr[selectedScraper]
  const [scrapersList, setScrapersList] = useState([])
  const [infoList, setInfoList] = useState([])
  const [segmentList, setSegmentList] = useState([])
  const [driveList, setDriveList] = useState([])
  const [learningCurveList, setLearningCurveList] = useState([])
  const [selectedScraperLearningCurveMessages, setSelectedScraperLearningCurveMessages] = useState([])
  const [activatedScraper, setActivatedScraper] = useState(null)

  const setSelectedScraper = (varNr) => {
    // remember selected scraper in redux store
    dispatch({ type: INFO_SCREEN_SCRAPER_SELECTED, payload: varNr })
  }

  const handleTabSelected = (index, lastIndex, event) => {
    // remember selected scraper tab in redux store
    dispatch({ type: INFO_SCREEN_SCRAPER_TAB_SELECTED, payload: index })
  }

  const { profile } = useSelector((state) => state.user)
  useShowConnectionSymbol(params.id)
  useSetMessageIcon()
  const eventsAvilable = ['S', 'M']
  const { error, messages, perPage, page, totalPages, scraperMessages } = useSelector(
    (state) => state.messages
  )

  // TODO: remember selected Scraper Tab and Selected sub tab so that we can restore it
  //       when navigating back to this page

  // when the current control id is not equal to the last one => reset the state

  // useEffect(() => {
  //   handleScraperClicked(1, 1)
  // },[]);
  const lastTabLevel = 2 // TODO: load this from redux store
  // const lastSelectedScraper = 1 // TODO: load this from redux store

  useSetControlTitle(params.id)

  useEffect(() => {
  }, [selectedScraperLearningCurveMessages])

  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: CUSTOMER_CONTROL_GET_VARIABLES_REQUEST, payload: params.id })
  }, [])

  const getLearningCurveScraperInfo = () => {
    setShowLoader(true)
    dispatch({ type: MESSAGE_SCRAPER_LIST_REQUEST, payload: 'q[event_type_id_eq]=4&q[field_module_id_eq]=' + params.id })
  }

  useEffect(() => {
    // on first render after variables have been loaded
    if (isMount.current) {
      setShowLoader(false)
      const listScrapers = mapVariables(Object.values(variables), BELT_CONVEYOR_SCRAPERS_LIST)
      const segmentVariables = mapVariables(Object.values(variables), BELT_CONVEYOR_SCRAPPER_SEGMENT_MAP)
      // listScrapers consists on the 4 variables that indicate the activation state of the scraper (601, 701, 801, 901)
      setScrapersList(listScrapers)
      // now the list is filtered so that only those are left that are activated (value !== 0)
      const firstActiveScraper = listScrapers.find((scraper) => Number(scraper.value) !== 0)
      const selectedScraperVariable = listScrapers.find((scraper) => Number(scraper.var_nr) == Number(selectedScraper))
      const selectedScraperIsActivated = !!selectedScraperVariable

      // show the tab of the previously selected scraper if that one is still activated
      if (selectedScraperIsActivated) {
        setActivatedScraper(selectedScraperVariable)
        updateScraperInfo(selectedScraper)
        setSelectedScraper(selectedScraper)
        getScraperMessages(selectedScraper, 10, 1)
      } else if (firstActiveScraper) {
        // else show the tab of the first scraper that is activated
        setActivatedScraper(firstActiveScraper)
        updateScraperInfo(firstActiveScraper.var_nr)
        setSelectedScraper(firstActiveScraper.var_nr)
        getScraperMessages(firstActiveScraper.var_nr, 10, 1)
      }
    } else {
      isMount.current = true
    }
  }, [variables])

  useEffect(() => {
    if (isErrorMount.current && error) {
      setShowLoader(false)
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isErrorMount.current = true
    }
  }, [error])

  const getScraperMessages = (scraper, perPage, page) => {
    setShowLoader(true)
    switch (scraper) {
      case variablesConstants.BELT_CONVEYOR_SCRAPER_1_NO:
        dispatch({ type: MESSAGE_LIST_REQUEST, payload: variablesConstants.BELT_CONVEYOR_SCRAPERS_1_MESSAGES_QUERY + `${params.id}&per_page=${perPage}&page=${page}`, per_page: perPage, page: page })
        break
      case variablesConstants.BELT_CONVEYOR_SCRAPER_2_NO:
        dispatch({ type: MESSAGE_LIST_REQUEST, payload: variablesConstants.BELT_CONVEYOR_SCRAPERS_2_MESSAGES_QUERY + `${params.id}&per_page=${perPage}&page=${page}`, per_page: perPage, page: page })
        break
      case variablesConstants.BELT_CONVEYOR_SCRAPER_3_NO:
        dispatch({ type: MESSAGE_LIST_REQUEST, payload: variablesConstants.BELT_CONVEYOR_SCRAPERS_3_MESSAGES_QUERY + `${params.id}&per_page=${perPage}&page=${page}`, per_page: perPage, page: page })
        break
      case variablesConstants.BELT_CONVEYOR_SCRAPER_4_NO:
        dispatch({ type: MESSAGE_LIST_REQUEST, payload: variablesConstants.BELT_CONVEYOR_SCRAPERS_4_MESSAGES_QUERY + `${params.id}&per_page=${perPage}&page=${page}`, per_page: perPage, page: page })
        break
      default:
        setShowLoader(false)
    }
  }

  useEffect(() => {
    if (isMessagesMount.current) {
      setShowLoader(false)
    } else {
      isMessagesMount.current = true
    }
  }, [messages])

  useEffect(() => {
    if (isScraperMessagesMount.current) {
      if (scraperMessages && scraperNumber) {
        setShowLoader(false)
        setSelectedScraperLearningCurveMessages(messagesByScraperNr(scraperNumber))
      }
    } else {
      isScraperMessagesMount.current = true
    }
  }, [scraperMessages, scraperNumber])

  // filters events (messages) by scraperNo to show only messages of the currently selected scraper
  const messagesByScraperNr = (scraperNo) => {
    return scraperMessages.map((message) => message.meta_data).filter((msg) => Number(msg.scraper) === Number(scraperNo)).sort((m1, m2) => {
      const timstamp1 = new Date(m1.timestamp)
      const timstamp2 = new Date(m2.timestamp)
      if (timstamp1 > timstamp2) return 1
      if (timstamp1 < timstamp2) return -1
      return 0
    })
  }

  // sets the current selected scraper index (4 tabs at the top)
  const handleScraperClicked = (scraperNo, i) => {
    updateScraperInfo(scraperNo)
    setSelectedScraper(scraperNo)
    getScraperMessages(scraperNo, 10, 1)
  }

  const updateScraperInfo = (selectedScraper) => {
    const scrapperInfo = getDataByScraper(selectedScraper, variablesConstants.BELT_CONVEYOR_SCRAPER_SUBGROUP_INFO)
    const scrapperSegment = getDataByScraper(selectedScraper, variablesConstants.BELT_CONVEYOR_SCRAPER_SUBGROUP_SEGMENT)
    const scrapperDrive = getDataByScraper(selectedScraper, variablesConstants.BELT_CONVEYOR_SCRAPER_SUBGROUP_DRIVE)
    const learningCurveLHS = getDataByScraper(selectedScraper, variablesConstants.BELT_CONVEYOR_SCRAPER_SUBGROUP_CURVE)
    setInfoList(scrapperInfo)
    setSegmentList(scrapperSegment)
    setDriveList(scrapperDrive)
    setLearningCurveList(learningCurveLHS)
  }

  const getDataByScraper = (scraper, subgroup) => {
    switch (scraper) {
      case variablesConstants.BELT_CONVEYOR_SCRAPER_1_NO:
        return getDataByScraperSubgroup(subgroup,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_1_INFO,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_1_SEGMENT,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_1_DRIVE,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_1_LEARNING_CURVE_LHS)
      case variablesConstants.BELT_CONVEYOR_SCRAPER_2_NO:
        return getDataByScraperSubgroup(subgroup,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_2_INFO,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_2_SEGMENT,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_2_DRIVE,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_2_LEARNING_CURVE_LHS)
      case variablesConstants.BELT_CONVEYOR_SCRAPER_3_NO:
        return getDataByScraperSubgroup(subgroup,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_3_INFO,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_3_SEGMENT,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_3_DRIVE,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_3_LEARNING_CURVE_LHS)
      case variablesConstants.BELT_CONVEYOR_SCRAPER_4_NO:
        return getDataByScraperSubgroup(subgroup,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_4_INFO,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_4_SEGMENT,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_4_DRIVE,
          variablesConstants.BELT_CONVEYOR_SCRAPERS_4_LEARNING_CURVE_LHS)
    }
  }

  const getDataByScraperSubgroup = (subgroup, info, segment, drive, learningCurve) => {
    switch (subgroup) {
      case variablesConstants.BELT_CONVEYOR_SCRAPER_SUBGROUP_INFO:
        return mapVariables(Object.values(variables), info)
      case variablesConstants.BELT_CONVEYOR_SCRAPER_SUBGROUP_SEGMENT:
        return mapVariables(Object.values(variables), segment)
      case variablesConstants.BELT_CONVEYOR_SCRAPER_SUBGROUP_DRIVE:
        return mapVariables(Object.values(variables), drive)
      case variablesConstants.BELT_CONVEYOR_SCRAPER_SUBGROUP_CURVE:
        return mapVariables(Object.values(variables), learningCurve)
    }
  }

  const getEventName = (message) => {
    if ([...eventsAvilable, 'W'].includes(message.event_type_short)) {
      return t('user_messages.event_shortcode_' + message.event_type_short)
    }
    return message.event_type_name
  }

  const onPageChange = (e, selectedPage) => {
    selectedPage = Number(selectedPage)
    getScraperMessages(selectedScraper, perPage, selectedPage)
  }

  const getFormula = (learningCurve) => {
    let varA = learningCurve.find((variable) => variable.meta && variable.meta.name === 'varA')
    let varB = learningCurve.find((variable) => variable.meta && variable.meta.name === 'varB')
    let varC = learningCurve.find((variable) => variable.meta && variable.meta.name === 'varC')
    let varD = learningCurve.find((variable) => variable.meta && variable.meta.name === 'varD')
    const varSymbolA = String(varA.value).startsWith('-') ? ' - ' : ''
    const varSymbolB = String(varB.value).startsWith('-') ? ' - ' : ' + '
    const varSymbolC = String(varC.value).startsWith('-') ? ' - ' : ' + '
    const varSymbolD = String(varD.value).startsWith('-') ? ' - ' : ' + '
    varA = { ...varA, value: String(varA.value).startsWith('-') ? String(varA.value).substr(1) : String(varA.value) }
    varB = { ...varB, value: String(varB.value).startsWith('-') ? String(varB.value).substr(1) : String(varB.value) }
    varC = { ...varC, value: String(varC.value).startsWith('-') ? String(varC.value).substr(1) : String(varC.value) }
    varD = { ...varD, value: String(varD.value).startsWith('-') ? String(varD.value).substr(1) : String(varD.value) }
    return (
      <span>
        {varSymbolA}<NumberFormat>{varA.value}</NumberFormat>x³{varSymbolB}<NumberFormat>{varB.value}</NumberFormat>x²{varSymbolC}<NumberFormat>{varC.value}</NumberFormat>x{varSymbolD}<NumberFormat>{varD.value}</NumberFormat>
      </span>)
  }

  return (
    <Card className={classes.root + ' CardBorderStyle border-0'} border={0}>
      <PollControlData />
      <Loader open={showLoader} />
      <CardHeader
        className={classes.cardHeader + ' MessageOuter'} action={
          <Box className={classes.addButton + ' mt-0'}>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={3}
            // style={{
            //   backgroundColor: filter === 'general' ? '#E8F4E6' : '#fff'
            // }}
            >
              {scrapersList && scrapersList.map((scraper, index) => {
                return (
                  <Grid item xs='3'>
                    <Button
                      disabled={Number(scraper.value_raw) === 0}
                      className={classes.messageBtn + ' ' + (Number(selectedScraper) === Number(scraper.var_nr) ? 'selected-scraper' : '')}
                      variant='outlined'
                      onClick={() => { handleScraperClicked(scraper.var_nr, index) }}
                    >
                      {t('conveyor_belt_info_belt_scraper.scraper_' + (index + 1))}
                    </Button>
                  </Grid>
                )
              })}

            </Grid>

          </Box>
        }
      />
      <CardContent>
        <Grid container className='MainContInnerStyle' alignContent='space-between'>
          <Grid item xs='12'>

            <Grid container direction='row' justifyContent='flex-end'>
              <Grid item xs={12}>
                <Tabs onSelect={handleTabSelected} selectedIndex={selectedScraperTab || 0} defaultIndex={selectedScraperTab || 0} className='justify-cotent-between TabsView ml-2 mr-2'>
                  <TabList>
                    {/* Info Tab */}
                    <Tab>{t('conveyor_belt_info_belt_scraper.info')}</Tab>
                    {/* Messages Tab */}
                    <Tab style={{ minWidth: '120px' }}>{t('conveyor_belt_info_belt_scraper.messages')}</Tab>
                    {/* Segment Tab */}
                    <Tab>{t('conveyor_belt_info_belt_scraper.segment')}</Tab>
                    {/* Drive Tab and Learning Curve Tab */}
                    {
                      profile && (profile.role === SERVICE_USER || profile.role === SUPER_USER)
                        ? <>
                          <Tab>{t('conveyor_belt_info_belt_scraper.drive')}</Tab>
                          <Tab>{t('conveyor_belt_info_belt_scraper.learning_curve')}</Tab>
                          </> : null
                    }
                  </TabList>

                  {/* Tab Content Info */}
                  <TabPanel>
                    <div className='Inner_scroll-con Pr-2  pl-1 pb-2 InfoTabContent'>
                      <Grid container spacing={1}>
                        {infoList && infoList.map((variable, i) => {
                          return (
                            <>
                              <Grid item xs={12}>
                                <Grid container direction='row' justifyContent='flex-end'>
                                  <Grid item xs={7} className='flex-fill'><strong>{variable.label} {variable.unit ? '[' + variable.unit + ']' : null}:</strong></Grid>
                                  <Grid item xs={5} className='flex-fill pl-2'>{variable.value}</Grid>
                                </Grid>
                              </Grid>
                              {i === 1 || i === 6 ? <Grid item xs={12} style={{ marginTop: 10 }} /> : null}
                            </>
                          )
                        })}
                      </Grid>

                      {infoList && !infoList.length && (
                        <div
                          style={{
                            padding: '1rem',
                            textAlign: 'center',
                            height: '100%',
                            position: 'absolute',
                            width: '100%',
                            top: '0',
                            right: '0',
                            display: 'flex',
                            alignContent: 'center',
                            fontSize: '20px',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          {t('noRecordsFound')}
                        </div>
                      )}
                    </div>
                  </TabPanel>
                  {/* Tab Content Messages */}
                  <TabPanel>
                    <div class='ConMessageBeltScraper PostionRelative'>

                      <div className='Inner_scroll-con pl-1 pr-1 pb-2'>
                        {activatedScraper && messages && messages.map((message) => {
                          return (
                            <Grid container direction='row' justifyContent='space-between' className='RowData RowData-list-service'>
                              <Grid item xs={11}>
                                <Grid container direction='row' justifyContent='space-between' className='pr-2 '>
                                  <Grid item xs={4} style={{ textAlign: 'left' }} className='left-Con pt-1 MessageEventTitle'>
                                    <Moment format='YYYY-MM-DD'>{message.timestamp}</Moment>
                                  </Grid>

                                  <Grid item xs={8} className='Mid-Con'>
                                    <Grid container direction='row' justifyContent='space-between' className='pt-1'>

                                      <Grid item className='pl-0 MessageEventTitle'>
                                        <strong>{message.event_title1}</strong>
                                      </Grid>

                                    </Grid>

                                  </Grid>
                                </Grid>

                                <Grid container direction='row' justifyContent='space-between' className='pr-2'>
                                  <Grid item xs={4} style={{ textAlign: 'left' }} className='left-Con '>
                                    <Moment format='HH:mm:ss'>{message.timestamp}</Moment>
                                  </Grid>

                                  <Grid item xs={8} className='Mid-Con'>
                                    <hr className='HrBorder' style={{ padding: 0, margin: 0 }} />
                                    {message.description1}
                                  </Grid>
                                </Grid>

                                <Grid container direction='row' justifyContent='space-between' className='pr-2'>
                                  <Grid item xs={4} style={{ textAlign: 'left' }} className='left-Con pb-1  MessageEventTitleBottom'>
                                    {getEventName(message)}
                                  </Grid>

                                  <Grid item xs={8} className='Mid-Con pb-1 MessageEventTitleBottom'>
                                    {message.description2}
                                  </Grid>
                                </Grid>

                              </Grid>
                              <Grid item xs={1} className='Last-Con cancel-icon-col'>
                                {message.event_type_short}
                              </Grid>
                            </Grid>)
                        })}
                      </div>
                      {(!activatedScraper || !messages.length) && (
                        <div
                          style={{
                            padding: '1rem',
                            textAlign: 'center',
                            height: '100%',
                            position: 'absolute',
                            width: '100%',
                            top: '0',
                            right: '0',
                            display: 'flex',
                            alignContent: 'center',
                            fontSize: '20px',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          {t('noRecordsFound')}
                        </div>
                      )}
                      <div className='pl-1 pr-1 ButtonPanelWrap'>
                        <Box className={actionsClasses.actionButtons + ' ButtonPanel pl-1 pr-1'}>
                          {
                            activatedScraper &&
                              <Pagination
                                onChange={onPageChange}
                                count={totalPages}
                                page={page} style={{ margin: 'auto' }} className='Pagination' showFirstButton showLastButton
                              />
                          }
                        </Box>
                      </div>
                    </div>
                  </TabPanel>

                  {/* Tab Content Segment */}
                  <TabPanel>
                    <TabSegment segmentList={segmentList} scraperNr={varNrToScraperNr[selectedScraper]} />
                  </TabPanel>

                  {/* Tab Content Drive */}
                  <TabPanel>
                    <div className='Inner_scroll-con pl-1 pr-1 pb-2 InfoTabContent'>
                      <Grid container spacing={1}>
                        {driveList && driveList.map((variable) => {
                          return (
                            <Grid item xs={12}>
                              <Grid container direction='row' justifyContent='space-between'>
                                <Grid item xs={7}><strong>{variable.label} {variable.unit ? '[' + variable.unit + ']' : null}:</strong></Grid>
                                <Grid item xs={5} className='flex-fill pl-2'>{variable.value}</Grid>
                              </Grid>
                            </Grid>
                          )
                        })}
                      </Grid>

                      {driveList && !driveList.length && (
                        <div
                          style={{
                            padding: '1rem',
                            textAlign: 'center',
                            height: '100%',
                            position: 'absolute',
                            width: '100%',
                            top: '0',
                            right: '0',
                            display: 'flex',
                            alignContent: 'center',
                            fontSize: '20px',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          {t('noRecordsFound')}
                        </div>
                      )}
                    </div>
                  </TabPanel>

                  {/* Tab Content Learning Curve */}
                  <TabPanel>
                    <div className='Inner_scroll-con pl-1 pr-1  pb-2 InfoTabContent'>
                      <Grid container direction='row' justifyContent='space-between'>

                        <Grid item xs={5}>
                          {
                            learningCurveList && learningCurveList.map((variable) => {
                              return (
                                <Grid container direction='row' justifyContent='space-between'>
                                  <Grid item xs={8}><strong>{variable.label}:</strong></Grid>
                                  <Grid item xs={4}><NumberFormat>{variable.value}</NumberFormat></Grid>
                                </Grid>
                              )
                            })
                          }
                          <br />
                          <br />
                          {
                            learningCurveList && learningCurveList.length > 0
                              ? <>
                                <strong>{t('conveyor_belt_info_belt_scraper.function')}:</strong><br />
                                Y = {learningCurveList && learningCurveList.length > 0 && getFormula(learningCurveList)}
                              </>
                              : null
                          }
                        </Grid>

                        <Grid item xs={7} className=''>

                          {selectedScraperLearningCurveMessages && selectedScraperLearningCurveMessages.length > 0
                            ? (
                              <div className='BorderPrimary'>
                                <table className='Custometable w-100' cellPadding='7'>

                                  <tr>
                                    <th />
                                    <th>{t('conveyor_belt_info_belt_scraper.date')}</th>
                                    <th>{t('conveyor_belt_info_belt_scraper.time')}</th>
                                    <th>{t('conveyor_belt_info_belt_scraper.current_value')}</th>
                                    <th>{t('conveyor_belt_info_belt_scraper.position')}</th>

                                  </tr>

                                  {
                                    selectedScraperLearningCurveMessages &&
                                  selectedScraperLearningCurveMessages.map((message, i) => {
                                    return (
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                          <Moment format='YYYY-MM-DD'>
                                            {message.timestamp}
                                          </Moment>
                                        </td>
                                        <td>
                                          <Moment format='HH:mm:ss'>
                                            {message.timestamp}
                                          </Moment>
                                        </td>
                                        <td>{message.value} mA</td>
                                        <td>{message.position} mm</td>
                                      </tr>
                                    )
                                  })
                                  }
                                </table>
                              </div>
                            )
                            : null}

                          {selectedScraperLearningCurveMessages && !selectedScraperLearningCurveMessages.length && (
                            <div
                              style={{
                                padding: '1rem',
                                textAlign: 'center',
                                height: '100%',
                                position: 'absolute',
                                width: '100%',
                                top: '0',
                                right: '0',
                                display: 'flex',
                                alignContent: 'center',
                                fontSize: '20px',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              {t('noRecordsFound')}
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </TabPanel>
                </Tabs>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Sidebars(ConveyorBeltInfoScraper)
