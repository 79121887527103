import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import theme from '../../theme'
import Loader from '../../components/Ux/Loader'
import PopupDialog from '../../components/PopupDialog'
import {
  CUSTOMER_CONTROL_LIST_REQUEST,
  CUSTOMER_CONTROL_DELETE_REQUEST
} from '../../store/customerControl/customerControlActions'
import Sidebars from '../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'
import ContentTitle from '../../components/content-titles/ContentTitle'
import ContentInfoTitle from '../../components/content-titles/ContentInfoTitle'
import { Grid } from '@material-ui/core'
import Moment from 'react-moment'
import { GET_CUSTOMER_REQUEST } from '../../store/customer/customerActions'
import Pagination from '@material-ui/lab/Pagination'
import ReadMoreReact from 'read-more-react'

const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }
}))
const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  table: {
    minWidth: 700
  },
  controlTable: {
    paddingBottom: '6em',
    [theme.breakpoints.up('lg')]: {
      paddingBottom: '45px'
    }
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  }
}))

const CustomerControl = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const actionsClasses = useActions()
  const isMount = useRef(false)
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()

  const [openDialog, setOpenDialog] = useState(false)
  const { customer } = useSelector((state) => state.customer)
  const [controlId, setControlId] = useState(null)
  const [newCustomerControls, setNewCustomerControls] = useState([])
  useSetParentCustomInnerHeaderHeading()
  const customerControlList = useSelector((state) => state.customerControl)
  const { loading, error, customerControls, perPage, page, totalPages, deletedId } = customerControlList

  // On Component Mount getting the customer data
  useEffect(() => {
    dispatch({ type: GET_CUSTOMER_REQUEST, payload: { id: params.id } })
  }, [])

  useEffect(() => {
    dispatch({
      type: CUSTOMER_CONTROL_LIST_REQUEST,
      payload: { id: params.id, data: { per_page: perPage, page } }
    })
  }, [dispatch])

  useEffect(() => {
    if (deletedId && controlId) {
      setControlId(null)
      dispatch({
        type: CUSTOMER_CONTROL_LIST_REQUEST,
        payload: { id: params.id, data: { per_page: perPage, page: 1 } }
      })
      toast.success(t('list_customer_controls.toast.control_deleted'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }, [deletedId])

  useEffect(() => {
    if (isMount.current) {
      setNewCustomerControls(customerControls)
    } else {
      isMount.current = true
      setNewCustomerControls([])
    }
  }, [customerControls])

  useEffect(() => {
    toast.error(error, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined
    })
  }, [error])

  const handleConfirmDeleteControl = (e) => {
    setOpenDialog(false)
    if (e.currentTarget.value === 'true') {
      dispatch({
        type: CUSTOMER_CONTROL_DELETE_REQUEST,
        payload: { id: controlId }
      })
    } else {
      setControlId(null)
    }
  }

  const handleDeleteControl = (id) => {
    setOpenDialog(true)
    setControlId(id)
  }

  const onPageChange = (e, selectedPage) => {
    selectedPage = Number(selectedPage)
    const payload = { per_page: perPage, page: selectedPage }
    dispatch({
      type: CUSTOMER_CONTROL_LIST_REQUEST,
      payload: {
        id: params.id,
        data: {
          ...payload
        }
      }
    })
  }

  // navigates to edit control
  const editControl = (control) => () => {
    history.push(`./${control.id}/edit`)
  }

  return (
    <>
      <Card className={classes.root + ' CardBorderStyle'}>
        <CardContent>
          <ContentTitle title={t('list_customer_controls.title')} />
          <ContentInfoTitle type={t('list_customer.title_singular')} name={customer && customer.name} no={customer && customer.customer_nr} />
          <Loader open={loading} />
          <Grid container alignContent='space-between' className={classes.controlTable + ' MainContInnerStyleTwo  list-user'}>
            <Grid container direction='row' alignItems='stretch' alignContent='center' justifyContent='space-between' className='RowDataHead pr-1 pl-1'>
              <Grid item xs={3} className='TextLeft MaxWidth336' />

              <Grid item xs={3} className='TextCenter flex-fill'> <strong>{t('list_customer_controls.id')}</strong>
              </Grid>

              <Grid item xs={3} className='TextCenter flex-fill'> <strong>{t('list_customer_controls.licenseNumber')}</strong>
              </Grid>

              <Grid item xs={2} className='TextCenter flex-fill'><strong>{t('list_customer_controls.validUntil')}</strong>
              </Grid>
              <Grid item xs={1} className='TextCenter cancel-icon-col flex-fill' />
            </Grid>
            <Grid item xs='12' className='Inner_scrollTwo listCustomerControl' style={{ paddingBottom: '65px' }}>

              <div className='Inner_scroll-con pl-1 pr-1 pt-0'>
                {newCustomerControls.map((control) => (
                  <Grid container key={control.id} direction='row' justifyContent='space-between' alignContent='center' alignItems='stretch' className='RowData TokenUpRow User '>
                    <Grid item xs={3} alignContent='center' className='TextLeft MaxWidth336 WordWrap ' onClick={editControl(control)} style={{ cursor: 'pointer' }}>
                      <strong style={{
                        color: !control.area_id ? 'red' : 'inherit'
                      }}
                      >
                        {control.name && <ReadMoreReact
                          text={control.name ? control.name : ''}
                          min={20}
                          max={20}
                          ideal={20}
                          readMoreText='...'
                                         />}
                      </strong>
                    </Grid>

                    <Grid item xs={3} alignItems='center' justifyContent='center' className='TextCenter flex-fill' onClick={editControl(control)} style={{ display: 'flex', cursor: 'pointer' }}>
                      {control.serial_number}
                    </Grid>

                    <Grid item xs={3} alignItems='center' justifyContent='center' className='TextCenter flex-fill' onClick={editControl(control)} style={{ display: 'flex', cursor: 'pointer' }}>
                      {control.license_number}
                    </Grid>

                    <Grid item xs={2} alignItems='center' justifyContent='center' className='TextCenter flex-fill' onClick={editControl(control)} style={{ display: 'flex', cursor: 'pointer' }}>
                      <Moment format='YYYY-MM-DD'>
                        {control.license_expires_at}
                      </Moment>
                    </Grid>
                    <Grid item xs={1} className='TextCenter cancel-icon cancel-icon-col TextLeft dflex align-items-center justify-content-center flex-fill'>
                      <img onClick={() => handleDeleteControl(control.id)} src='/assets/img/cancel.png' style={{ cursor: 'pointer' }} />
                    </Grid>
                  </Grid>
                ))}
              </div>
            </Grid>
          </Grid>
          {!newCustomerControls.length && (
            <div
              style={{
                padding: '1rem',
                textAlign: 'center',
                height: '100%',
                position: 'absolute',
                width: '100%',
                top: '0',
                right: '0',
                display: 'flex',
                alignContent: 'center',
                fontSize: '20px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {t('noRecordsFound')}
            </div>

          )}
        </CardContent>
        <CardActions className='pl-1 pr-1 ButtonPanelWrap' style={{ flexDirection: 'column', alignItems: 'center' }}>
          <Box className={actionsClasses.actionButtons + ' ButtonPanel pl-1 pr-1'}>
            <Button
              style={{ marginRight: '0.625rem' }}
              onClick={() => history.push('./add/')}
              type='button'
              variant='outlined'
            >
              <img src='/assets/img/plus.png' />&nbsp;&nbsp;
              {t('list_customer_controls.newControl')}
            </Button>
            {newCustomerControls.length && totalPages ? <Pagination className='Pagination' page={page} onChange={onPageChange} count={totalPages} showFirstButton showLastButton /> : null}
            <Button
              style={{ marginRight: '0.625rem' }}
              onClick={() => history.push('./areas/')}
              type='button'
              variant='outlined'
            >
              <img src='/assets/img/sport.png' />&nbsp;&nbsp;
              {t('list_customer_controls.areaAllocation')}
            </Button>
          </Box>
        </CardActions>
      </Card>
      <PopupDialog open={openDialog} onClick={handleConfirmDeleteControl} />
    </>
  )
}

export default Sidebars(CustomerControl, { showSearch: false })
