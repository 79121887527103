import React, { useEffect, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import theme from '../../theme'
import * as roles from '../../constants/roles'
import {
  MESSAGE_EVENTS_LIST_REQUEST,
  MESSAGE_LIST_REQUEST,
  MESSAGE_BULK_READ_REQUEST
} from '../../store/message/messageAction'
import Loader from '../../components/Ux/Loader'
import {
  REPRESENTATION_USER,
  TOP_USER,
  USER
} from '../../constants/roles'
import Moment from 'react-moment'
import CardActions from '@material-ui/core/CardActions'

import Sidebars from '../../components/layouts/Sidebars'
import Pagination from '@material-ui/lab/Pagination'
import useSetMessageIcon from '../../components/hooks/useSetMessageIcon'
import useSetParentCustomInnerHeaderHeading from '../../components/hooks/useSetParentCustomInnerHeaderHeading'

const useActions = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      fontWeight: '400',
      textTransform: 'inherit'
    }
  }

}))

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  table: {
    minWidth: 700
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  },
  messageBtn: {
    minWidth: '100%',
    minHeight: '60px',
    border: '1px solid black',
    borderRadius: 'none',
    '&:hover': { background: '#f5f5f5 !important' }
  }
})

const Messages = ({ generalButtonText, noRoles, customQueryKey, customQueryValue, hideEventTitle2 }) => {
  const location = useLocation()
  const { t } = useTranslation()
  const classes = useStyles()
  const actionsClasses = useActions()

  const { events, error, messages, perPage, page, totalPages } = useSelector(
    (state) => state.messages
  )
  const { user, scopeRepresentativeId, scopeCustomerId } = useSelector((state) => state.user)
  const profile = useSelector((state) => state.user.profile)
  const dispatch = useDispatch()
  const isMount = useRef(false)
  const isEventsMount = useRef(false)
  const isMessagesMount = useRef(false)
  const isReadRequest = useRef(true)
  const [filter, setFilter] = useState('general')
  const eventsAvailable = ['S', 'M']
  const [showLoader, setShowLoader] = useState(false)
  useSetParentCustomInnerHeaderHeading()
  useSetMessageIcon(user.role === roles.SUPER_USER ||
    user.role === roles.SERVICE_USER ||
    user.role === roles.REPRESENTATION_USER)
  const getMessagesQuery = (filter, selectedPage) => {
    const queryToken = 'q[event_type_id_in][]'
    const rfidQueryToken = 'q[rfid_uid_present]'
    const customerToken = 'q[customer_id_eq]'
    const representationToken = 'q[representation_id_eq]'
    let query = ''

    // general is the left tab which contains all events
    if (filter === 'general') {
      const ids = events
        .filter((event) => [...eventsAvailable, 'W', 'B'].includes(event.name_short))
        .map((e) => e.id)
      query = ids.reduce((previous, current, index) => {
        let subQuery = previous + queryToken + '=' + current
        if (index !== ids.length - 1) {
          subQuery += '&'
        }
        return subQuery
      }, query)
    }

    // 1 = Errors
    // 2 = Warnings
    // 9 = Belt Damages
    if (Number(filter) === 1) {
      query = queryToken + '=' + Number(filter)
      // also show belt damages
      query += '&' + queryToken + '=' + 9
    }
    if (Number(filter) === 2) {
      query = queryToken + '=' + Number(filter)
    }

    // rfid is the right tab which only contains rfid events
    if (filter === 'rfid') {
      query = rfidQueryToken + '=true'
    }

    if (!query) {
      return false
    }

    if (!noRoles) {
      if (scopeRepresentativeId || scopeCustomerId) {
        if (scopeRepresentativeId) {
          query += '&' + representationToken + '=' + scopeRepresentativeId
        } else if (scopeCustomerId) {
          query += '&' + customerToken + '=' + scopeCustomerId
        }
      } else {
        switch (user.role) {
          case USER:
          case TOP_USER:
            query += '&' + customerToken + '=' + profile.customer_id
            break
          case REPRESENTATION_USER:
            query += '&' + representationToken + '=' + profile.representation_id
        }
      }
    }

    if (customQueryKey) {
      query += '&' + customQueryKey + '=' + customQueryValue
    }
    query += '&per_page=' + perPage
    query += '&page=' + (selectedPage || page)
    return query
  }

  const getSearchType = (queryData) => {
    switch (queryData) {
      case 'desruptions':
        return 1
      case 'messages':
        return 2
      default :
        return false
    }
  }
  useEffect(() => {
    setShowLoader(true)
    dispatch({ type: MESSAGE_EVENTS_LIST_REQUEST, payload: { 'q[sorts]': 'id desc' } })
  }, [])

  useEffect(() => {
    if (isMessagesMount.current) {
      setShowLoader(false)
      if ((user.role === roles.TOP_USER || user.role === roles.USER || scopeCustomerId) &&
          isReadRequest.current &&
          messages &&
          messages.length > 0 &&
          (Number(filter) === 1 || Number(filter) === 2 || filter === 'general')) {
        isReadRequest.current = false
        if (filter === 'general') {
          dispatch({ type: MESSAGE_BULK_READ_REQUEST, payload: { read_until: (new Date()).toISOString(), event_type_id: 1 } })
          dispatch({ type: MESSAGE_BULK_READ_REQUEST, payload: { read_until: (new Date()).toISOString(), event_type_id: 2 } })
          return
        }
        dispatch({ type: MESSAGE_BULK_READ_REQUEST, payload: { read_until: (new Date()).toISOString(), event_type_id: filter } })
      }
    } else {
      isMessagesMount.current = true
    }
  }, [messages])

  useEffect(() => {
    if (isMount.current && error) {
      setShowLoader(false)
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isMount.current = true
    }
  }, [error])

  useEffect(() => {
    if (isEventsMount.current) {
      if (events && events.length > 0) {
        const params = getMessagesQuery(filter)
        if (!params) {
          setShowLoader(false)
          toast.error('Invalid filters', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
          })
          return
        }

        if (location.search) {
          const searchTokens = location.search.substr(1).split('&').map((parameters) => parameters.split('='))
          if (searchTokens.length === 1 && searchTokens[0][0] === 'type') {
            const searchType = getSearchType(searchTokens[0][1])
            if (searchType) {
              getFilteredData(searchType, 1)
              return
            }
          }
        }

        dispatch({ type: MESSAGE_LIST_REQUEST, payload: params, per_page: 10, page: 1 })
      }
      if (!events || events.length === 0) {
        setShowLoader(false)
        toast.error('No events found', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined
        })
      }
    } else {
      isEventsMount.current = true
    }
  }, [events])

  const handleFilterChange = (e) => {
    getFilteredData(e.currentTarget.value, 1)
  }

  const getFilteredData = (value, selectedPage) => {
    setFilter(value)
    selectedPage = Number(selectedPage)
    const params = getMessagesQuery(value, selectedPage)
    setShowLoader(true)
    dispatch({ type: MESSAGE_LIST_REQUEST, payload: params, per_page: perPage, page: selectedPage || page })
  }

  const getEventName = (message) => {
    if ([...eventsAvailable, 'W'].includes(message.event_type_short)) {
      return t('user_messages.event_shortcode_' + message.event_type_short)
    }
    return message.event_type_name
  }

  const onPageChange = (e, selectedPage) => {
    getFilteredData(filter, selectedPage)
    if ((user.role === roles.TOP_USER || user.role === roles.USER || scopeCustomerId) &&
    messages &&
    messages.length > 0 &&
    (Number(filter) === 1 || Number(filter) === 2 || filter === 'general')) {
      if (filter === 'general') {
        dispatch({ type: MESSAGE_BULK_READ_REQUEST, payload: { read_until: (new Date()).toISOString(), event_type_id: 1 } })
        dispatch({ type: MESSAGE_BULK_READ_REQUEST, payload: { read_until: (new Date()).toISOString(), event_type_id: 2 } })
        return
      }
      dispatch({ type: MESSAGE_BULK_READ_REQUEST, payload: { read_until: (new Date()).toISOString(), event_type_id: filter } })
    }
  }

  return (
    <Card className={classes.root + ' CardBorderStyle border-0 position-relative'} border={0}>
      <Loader open={showLoader} />

      <CardHeader
        className={classes.cardHeader + ' MessageOuter'} action={
          <Box className={classes.addButton + ' mt-0 mb-0 ml-0 mr-0'}>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={2}
            >
              <Grid item xs='3'>
                <Button
                  variant='outlined'
                  className={classes.messageBtn}
                  value='general'
                  onClick={handleFilterChange}
                  style={{
                    backgroundColor: filter === 'general' ? '#E8F4E6' : '#fff'
                  }}
                >
                  {generalButtonText || t('user_messages.event_shortcode_overview')}
                </Button>
              </Grid>
              {events
                .filter((event) => eventsAvailable.includes(event.name_short))
                .map((event) => {
                  return (
                    <Grid item xs='3'>
                      <Button
                        variant='outlined'
                        className={classes.messageBtn}
                        value={event.id}
                        onClick={handleFilterChange}
                        style={{
                          backgroundColor:
                              Number(filter) === Number(event.id) ? '#E8F4E6' : '#fff'
                        }}
                      >
                        {t('user_messages.event_shortcode_' + event.name_short + '_title')}
                      </Button>
                    </Grid>
                  )
                })}
              <Grid item xs='3'>
                <Button
                  variant='outlined'
                  className={classes.messageBtn}
                  value='rfid'
                  onClick={handleFilterChange}
                  style={{
                    backgroundColor: filter === 'rfid' ? '#E8F4E6' : '#fff'
                  }}
                >
                  {t('user_messages.event_shortcode_RFID')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        }
      />
      <div className=' BorderPrimary Message-div'>
        <CardContent className='h-100'>
          <Grid container alignContent='space-between' className='MainContInnerStyle h-100'>
            <Grid item xs='12' className='Inner_scroll pl-1 pr-1'>
              <div className='Inner_scroll-con pl-1 pr-1 pt-0'>
                {messages.map((message) => (
                  <Grid container direction='row' justifyContent='space-between' className='RowData RowData-list-service'>

                    <Grid item xs={11}>
                      <Grid container direction='row' justifyContent='space-between' className='pr-2 '>
                        <Grid item xs={4} style={{ textAlign: 'left' }} className='left-Con pt-1 MessageEventTitle'>
                          <Moment format='YYYY-MM-DD'>{message.timestamp}</Moment>
                        </Grid>

                        <Grid item xs={8} className='Mid-Con'>
                          <Grid container direction='row' justifyContent='space-between' className='pt-1'>

                            <Grid item className='pl-0 MessageEventTitle'>
                              <strong>{message.event_title1}</strong>
                            </Grid>

                            <Grid item textAlign='right' className=' MessageEventTitle'>
                              {
                                !hideEventTitle2
                                  ? message.event_title2
                                  : null
                              }
                            </Grid>
                          </Grid>

                        </Grid>
                      </Grid>

                      <Grid container direction='row' justifyContent='space-between' className='pr-2'>
                        <Grid item xs={4} style={{ textAlign: 'left' }} className='left-Con '>
                          <Moment format='HH:mm:ss'>{message.timestamp}</Moment>
                        </Grid>

                        <Grid item xs={8} className='Mid-Con'>
                          <hr className='HrBorder' style={{ padding: 0, margin: 0 }} />
                          {message.description1}
                        </Grid>
                      </Grid>

                      <Grid container direction='row' justifyContent='space-between' className='pr-2'>
                        <Grid item xs={4} style={{ textAlign: 'left' }} className='left-Con pb-1  MessageEventTitleBottom'>
                          {getEventName(message)}
                        </Grid>

                        <Grid item xs={8} className='Mid-Con pb-1 MessageEventTitleBottom'>
                          {message.description2}
                        </Grid>
                      </Grid>

                    </Grid>

                    <Grid item xs={1} className='Last-Con cancel-icon-col'>
                      {message.event_type_short}
                    </Grid>
                  </Grid>
                ))}
              </div>
            </Grid>
          </Grid>
          {messages && !messages.length && (
            <div
              style={{
                padding: '1rem',
                textAlign: 'center',
                height: '100%',
                position: 'absolute',
                width: '100%',
                top: '0',
                right: '0',
                display: 'flex',
                alignContent: 'center',
                fontSize: '20px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {t('noRecordsFound')}
            </div>
          )}
        </CardContent>

        <CardActions className='pl-1 pr-1 ButtonPanelWrap'>
          <Box className={actionsClasses.actionButtons + ' ButtonPanel pl-1 pr-1'}>
            <Pagination
              style={{ margin: 'auto' }}
              className='Pagination'
              onChange={onPageChange}
              count={totalPages}
              page={page}
              showFirstButton showLastButton
            />
          </Box>
        </CardActions>
      </div>
    </Card>
  )
}

export default Sidebars(Messages)
