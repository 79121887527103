import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LineChart, XAxis, Tooltip, CartesianGrid, Line, YAxis, ResponsiveContainer } from 'recharts'
import useWindowResize from '../../../../components/hooks/useWindowResize'
import moment from 'moment'

const getRelevantChartData = (data, volumeCritPct, isCarbideSegment) => {
  if (isCarbideSegment) {
    let mergedData = data.segmentMeasurements.map((x) => ({ timestamp: x[0], carbideMeasurement: Number(x[1]), volumeCritPct }))
    mergedData = mergedData.concat(data.segmentPredictions.map((x) => ({ timestamp: x[0], carbidePrediction: Number(x[1]), volumeCritPct })))
    return mergedData.sort((a, b) => a.timestamp - b.timestamp)
  } else {
    return data.estimatedWearNonCarbide.map((x) => ({ timestamp: x[0], nonCarbideEstimation: Number(x[1]), volumeCritPct }))
  }
}

const LineChartTooltip = (props) => {
  const { t } = useTranslation()
  const { payload, label } = props || {}
  return (
    <div style={{ backgroundColor: 'white', border: 'solid 1px #aaaaaa', padding: '10px' }}>
      <div><span>{t('conveyor_belt_info_scraper_segment_tab.chart.labels.timestamp')}</span>: <span>{moment(label).format("DD.MM.YY HH:mm:ss")}</span></div>
      {payload && payload.map(item => <div style={{ marginTop: '5px' }}><span style={{ color: item.color }}>{t(`conveyor_belt_info_scraper_segment_tab.chart.labels.${item.name}`)}</span>: <span>{Number.parseFloat(item.value).toFixed(1)}%</span></div>)}
    </div>
  )
}

function SegmentChart ({ data, volumeCritPct, isCarbideSegment }) {
  const [height, setHeight] = useState(0)
  const [chartData, setChartData] = useState(null);
  const windowDimensions = useWindowResize()
  useEffect(() => {
    setTimeout(() => {
      const chartContainer = document.querySelector('.chart-container')
      setHeight(chartContainer.clientHeight)
    }, 500)
  }, [windowDimensions])

  // this is only necessary because of this bug in recharts
  // https://github.com/recharts/recharts/issues/1426#issuecomment-1293516480
  React.useEffect(() => {
    if (data != null) {
      setChartData(getRelevantChartData(data, volumeCritPct, isCarbideSegment));
    }
  }, [data, volumeCritPct, isCarbideSegment]);

  if (data == null) {
    return <></>
  }


  return (
    height !== 0
      ? <div style={{
        width: '100%',
        height: height - 65,
        fontSize: '10px'
      }}
      >
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart
            data={chartData}
          >
            <XAxis tickCount={10} 
                   tickFormatter={unixTime => moment(unixTime).format("DD.MM.YY")}
                   allowDecimals height={20} axisLine={false} tickLine={false} dataKey='timestamp' />
            <YAxis
              width={40} type='number' domain={[0, 100]} tickFormatter={(tick) => {
                return `${Math.floor(Number(tick))}%`
              }}
            />

            <Tooltip content={LineChartTooltip} />
            <CartesianGrid strokeDasharray='3 3' />

            {isCarbideSegment && <Line type='monotone' connectNulls dataKey='volumeCritPct' stroke='#f93600' dot={false} activeDot={{ r: 8 }} />}
            {isCarbideSegment && <Line type='monotone' connectNulls dataKey='carbideMeasurement' stroke='#097217' dot={true} activeDot={{ r: 8 }} />}
            {isCarbideSegment && <Line type='monotone' connectNulls dataKey='carbidePrediction' stroke='#8c8e4d' dot={false} activeDot={{ r: 8 }} />}
            {!isCarbideSegment && <Line type='monotone' connectNulls dataKey='nonCarbideEstimation' stroke='#0000ff' dot={false} activeDot={{ r: 8 }} />}
          </LineChart>
        </ResponsiveContainer>
        </div> : null
  )
}

export default SegmentChart
